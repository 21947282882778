@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: #0e76bc;
    --secondary: #0e76bcc2;
    --tertiary: #d0ddf0;
    --floor: #e5e7eb;
    --dark: #222d35;
  }

  li,
  Link,
  a {
    @apply cursor-pointer text-dark;
  }
  .default-input {
    @apply text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-secondary focus:text-gray-600;
  }
  .form-label {
    @apply font-semibold text-gray-600 text-[14.5px];
  }
  .text-input {
    @apply mt-1 block w-full px-2 py-[4px] border-gray-400 rounded border-[1.5px] focus:border-blue-400 hover:border-blue-400 text-gray-900  focus:outline-none  bg-transparent mb-2 hover:duration-300;
  }
  .account-form-input {
    @apply mt-1 block w-full px-3 py-[8px] border-gray-200 rounded-xl border-[1px]  text-[14px]  bg-transparent mb-2 text-gray-500;
  }
}

@font-face {
  font-family: "GT-Walsheim-Bold";
  src: local("GTWalsheim-Bold"),
    url("../public/fonts/GTWalsheim-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "GT-Walsheim-Regular";
  src: local("GTWalsheim-Regular"),
    url("../public/fonts/GTWalsheim-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "GT Walsheim";
  src: local("GTWalsheim"), url("../public/fonts/GTWalsheim.ttf") format("truetype");
}

html,
body {
  scroll-behavior: smooth !important;
}

img,
video {
  max-width: 108%;
  height: auto;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list,
.slick-slider {
  position: relative;
  display: block;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
}

.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "\2190";
}

[dir="rtl"] .slick-prev:before {
  content: "\2192";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "\2192";
}

[dir="rtl"] .slick-next:before {
  content: "\2190";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: none;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "\2022";
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}

.fv-sr-only {
  display: none;
}

.fv-plugins-framework input::-ms-clear,
.fv-plugins-framework textarea::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

.fv-plugins-icon-container {
  position: relative;
}

.fv-plugins-icon {
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}

.fv-plugins-tooltip {
  position: absolute;
  z-index: 10000;
}

.fv-plugins-tooltip .fv-plugins-tooltip__content {
  background: #000;
  border-radius: 3px;
  color: #eee;
  padding: 10px;
  position: relative;
}

.fv-plugins-tooltip .fv-plugins-tooltip__content:before {
  border: 8px solid transparent;
  content: "";
  position: absolute;
}

.fv-plugins-tooltip--hide {
  opacity: 0;
}

.fv-plugins-tooltip--top-left {
  margin-top: 8px;
}

.fv-plugins-tooltip--top-left .fv-plugins-tooltip__content:before {
  border-top-color: #000;
  left: 8px;
  top: 100%;
}

.fv-plugins-tooltip--top {
  margin-top: 8px;
}

.fv-plugins-tooltip--top .fv-plugins-tooltip__content:before {
  border-top-color: #000;
  left: 50%;
  margin-left: -8px;
  top: 100%;
}

.fv-plugins-tooltip--top-right {
  margin-top: 8px;
}

.fv-plugins-tooltip--top-right .fv-plugins-tooltip__content:before {
  border-top-color: #000;
  right: 8px;
  top: 100%;
}

.fv-plugins-tooltip--right .fv-plugins-tooltip__content:before {
  border-right-color: #000;
  margin-top: -8px;
  right: 100%;
  top: 50%;
}

.fv-plugins-tooltip--bottom-right {
  margin-top: 8px;
}

.fv-plugins-tooltip--bottom-right .fv-plugins-tooltip__content:before {
  border-bottom-color: #000;
  bottom: 100%;
  right: 8px;
}

.fv-plugins-tooltip--bottom {
  margin-top: 8px;
}

.fv-plugins-tooltip--bottom .fv-plugins-tooltip__content:before {
  border-bottom-color: #000;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
}

.fv-plugins-tooltip--bottom-left {
  margin-top: 8px;
}

.fv-plugins-tooltip--bottom-left .fv-plugins-tooltip__content:before {
  border-bottom-color: #000;
  bottom: 100%;
  left: 8px;
}

.fv-plugins-tooltip--left .fv-plugins-tooltip__content:before {
  border-left-color: #000;
  left: 100%;
  margin-top: -8px;
  top: 50%;
}

.fv-plugins-tooltip-icon {
  cursor: pointer;
  pointer-events: inherit;
}

.fv-plugins-bootstrap .fv-help-block {
  color: #dc3545;
  font-size: 80%;
  margin-top: 0.25rem;
}

.fv-plugins-bootstrap .is-invalid ~ .form-check-label,
.fv-plugins-bootstrap .is-valid ~ .form-check-label {
  color: inherit;
}

.fv-plugins-bootstrap .has-danger .fv-plugins-icon {
  color: #dc3545;
}

.fv-plugins-bootstrap .has-success .fv-plugins-icon {
  color: #28a745;
}

.fv-plugins-bootstrap .fv-plugins-icon {
  height: 38px;
  line-height: 38px;
  width: 38px;
}

.fv-plugins-bootstrap .input-group ~ .fv-plugins-icon {
  z-index: 3;
}

.fv-plugins-bootstrap .form-group.row .fv-plugins-icon {
  right: 15px;
}

.fv-plugins-bootstrap .form-group.row .fv-plugins-icon-check {
  top: -7px;
}

.fv-plugins-bootstrap:not(.form-inline) label ~ .fv-plugins-icon {
  top: 32px;
}

.fv-plugins-bootstrap:not(.form-inline) label ~ .fv-plugins-icon-check {
  top: 25px;
}

.fv-plugins-bootstrap:not(.form-inline) label.sr-only ~ .fv-plugins-icon-check {
  top: -7px;
}

.fv-plugins-bootstrap.form-inline .form-group {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: auto;
}

.fv-plugins-bootstrap .form-control.is-invalid,
.fv-plugins-bootstrap .form-control.is-valid {
  background-image: none;
}

.fv-plugins-bootstrap3 .help-block {
  margin-bottom: 0;
}

.fv-plugins-bootstrap3 .input-group ~ .form-control-feedback {
  z-index: 4;
}

.fv-plugins-bootstrap3.form-inline .form-group {
  vertical-align: top;
}

.fv-plugins-bulma .field.has-addons {
  flex-wrap: wrap;
}

.fv-plugins-bulma .field.has-addons:after {
  content: "";
  width: 100%;
}

.fv-plugins-bulma .field.has-addons .fv-plugins-message-container {
  order: 1;
}

.fv-plugins-bulma .icon.fv-plugins-icon-check {
  top: -4px;
}

.fv-plugins-bulma .fv-has-error .input,
.fv-plugins-bulma .fv-has-error .textarea {
  border: 1px solid #ff3860;
}

.fv-plugins-bulma .fv-has-success .input,
.fv-plugins-bulma .fv-has-success .textarea {
  border: 1px solid #23d160;
}

.fv-plugins-foundation .fv-plugins-icon {
  height: 39px;
  line-height: 39px;
  right: 0;
  width: 39px;
}

.fv-plugins-foundation .grid-padding-x .fv-plugins-icon {
  right: 15px;
}

.fv-plugins-foundation .fv-plugins-icon-container .cell {
  position: relative;
}

.fv-plugins-foundation [type="checkbox"] ~ .fv-plugins-icon {
  top: -7px;
}

.fv-plugins-foundation.fv-stacked-form .fv-plugins-message-container {
  width: 100%;
}

.fv-plugins-foundation.fv-stacked-form
  fieldset
  [type="checkbox"]
  ~ .fv-plugins-icon,
.fv-plugins-foundation.fv-stacked-form
  fieldset
  [type="radio"]
  ~ .fv-plugins-icon,
.fv-plugins-foundation.fv-stacked-form label .fv-plugins-icon {
  top: 25px;
}

.fv-plugins-foundation .form-error {
  display: block;
}

.fv-plugins-foundation .fv-row__success .fv-plugins-icon {
  color: #3adb76;
}

.fv-plugins-foundation .fv-row__error .fv-plugins-icon,
.fv-plugins-foundation .fv-row__error fieldset legend,
.fv-plugins-foundation .fv-row__error label {
  color: #cc4b37;
}

.fv-plugins-materialize .fv-plugins-icon {
  height: 42px;
  line-height: 42px;
  width: 42px;
}

.fv-plugins-materialize .fv-plugins-icon-check {
  top: -10px;
}

.fv-plugins-materialize .fv-invalid-row .fv-plugins-icon,
.fv-plugins-materialize .fv-invalid-row .helper-text {
  color: #f44336;
}

.fv-plugins-materialize .fv-valid-row .fv-plugins-icon,
.fv-plugins-materialize .fv-valid-row .helper-text {
  color: #4caf50;
}

.fv-plugins-milligram .fv-plugins-icon {
  height: 38px;
  line-height: 38px;
  width: 38px;
}

.fv-plugins-milligram .column {
  position: relative;
}

.fv-plugins-milligram .column .fv-plugins-icon {
  right: 10px;
}

.fv-plugins-milligram .fv-plugins-icon-check {
  top: -6px;
}

.fv-plugins-milligram .fv-plugins-message-container {
  margin-bottom: 15px;
}

.fv-plugins-milligram.fv-stacked-form .fv-plugins-icon {
  top: 30px;
}

.fv-plugins-milligram.fv-stacked-form .fv-plugins-icon-check {
  top: 24px;
}

.fv-plugins-milligram .fv-invalid-row .fv-help-block,
.fv-plugins-milligram .fv-invalid-row .fv-plugins-icon {
  color: red;
}

.fv-plugins-milligram .fv-valid-row .fv-help-block,
.fv-plugins-milligram .fv-valid-row .fv-plugins-icon {
  color: green;
}

.fv-plugins-mini .fv-plugins-icon {
  height: 42px;
  line-height: 42px;
  width: 42px;
  top: 4px;
}

.fv-plugins-mini .fv-plugins-icon-check {
  top: -8px;
}

.fv-plugins-mini.fv-stacked-form .fv-plugins-icon {
  top: 28px;
}

.fv-plugins-mini.fv-stacked-form .fv-plugins-icon-check {
  top: 20px;
}

.fv-plugins-mini .fv-plugins-message-container {
  margin: calc(var(--universal-margin) / 2);
}

.fv-plugins-mini .fv-invalid-row .fv-help-block,
.fv-plugins-mini .fv-invalid-row .fv-plugins-icon {
  color: var(--input-invalid-color);
}

.fv-plugins-mini .fv-valid-row .fv-help-block,
.fv-plugins-mini .fv-valid-row .fv-plugins-icon {
  color: #308732;
}

.fv-plugins-mui .fv-plugins-icon {
  height: 32px;
  line-height: 32px;
  width: 32px;
  top: 15px;
  right: 4px;
}

.fv-plugins-mui .fv-plugins-icon-check {
  top: -6px;
  right: -10px;
}

.fv-plugins-mui .fv-plugins-message-container {
  margin: 8px 0;
}

.fv-plugins-mui .fv-invalid-row .fv-help-block,
.fv-plugins-mui .fv-invalid-row .fv-plugins-icon {
  color: #f44336;
}

.fv-plugins-mui .fv-valid-row .fv-help-block,
.fv-plugins-mui .fv-valid-row .fv-plugins-icon {
  color: #4caf50;
}

.fv-plugins-pure .fv-plugins-icon {
  height: 36px;
  line-height: 36px;
  width: 36px;
}

.fv-plugins-pure .fv-has-error .fv-help-block,
.fv-plugins-pure .fv-has-error .fv-plugins-icon,
.fv-plugins-pure .fv-has-error label {
  color: #ca3c3c;
}

.fv-plugins-pure .fv-has-success .fv-help-block,
.fv-plugins-pure .fv-has-success .fv-plugins-icon,
.fv-plugins-pure .fv-has-success label {
  color: #1cb841;
}

.fv-plugins-pure.pure-form-aligned .fv-help-block {
  margin-top: 5px;
  margin-left: 180px;
}

.fv-plugins-pure.pure-form-aligned .fv-plugins-icon-check {
  top: -9px;
}

.fv-plugins-pure.pure-form-stacked .pure-control-group {
  margin-bottom: 8px;
}

.fv-plugins-pure.pure-form-stacked .fv-plugins-icon {
  top: 22px;
}

.fv-plugins-pure.pure-form-stacked .fv-plugins-icon-check {
  top: 13px;
}

.fv-plugins-pure.pure-form-stacked .fv-sr-only ~ .fv-plugins-icon {
  top: -9px;
}

.fv-plugins-semantic .error .fv-plugins-icon,
.fv-plugins-semantic.ui.form .fields.error label {
  color: #9f3a38;
}

.fv-plugins-semantic .fv-plugins-icon-check {
  right: 7px;
}

.fv-plugins-shoelace .input-group {
  margin-bottom: 0;
}

.fv-plugins-shoelace .fv-plugins-icon {
  height: 32px;
  line-height: 32px;
  width: 32px;
  top: 28px;
}

.fv-plugins-shoelace .row .fv-plugins-icon {
  right: 16px;
  top: 0;
}

.fv-plugins-shoelace .fv-plugins-icon-check {
  top: 24px;
}

.fv-plugins-shoelace .fv-sr-only ~ .fv-plugins-icon,
.fv-plugins-shoelace .fv-sr-only ~ div .fv-plugins-icon {
  top: -4px;
}

.fv-plugins-shoelace .input-valid .fv-help-block,
.fv-plugins-shoelace .input-valid .fv-plugins-icon {
  color: #2ecc40;
}

.fv-plugins-shoelace .input-invalid .fv-help-block,
.fv-plugins-shoelace .input-invalid .fv-plugins-icon {
  color: #ff4136;
}

.fv-plugins-spectre .input-group .fv-plugins-icon {
  z-index: 2;
}

.fv-plugins-spectre .form-group .fv-plugins-icon-check {
  right: 6px;
  top: 10px;
}

.fv-plugins-spectre:not(.form-horizontal) .form-group .fv-plugins-icon-check {
  right: 6px;
  top: 45px;
}

.fv-plugins-tachyons .fv-plugins-icon {
  height: 36px;
  line-height: 36px;
  width: 36px;
}

.fv-plugins-tachyons .fv-plugins-icon-check {
  top: -7px;
}

.fv-plugins-tachyons.fv-stacked-form .fv-plugins-icon {
  top: 34px;
}

.fv-plugins-tachyons.fv-stacked-form .fv-plugins-icon-check {
  top: 24px;
}

.fv-plugins-turret .fv-plugins-icon {
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.fv-plugins-turret.fv-stacked-form .fv-plugins-icon {
  top: 29px;
}

.fv-plugins-turret.fv-stacked-form .fv-plugins-icon-check {
  top: 17px;
}

.fv-plugins-turret .fv-invalid-row .form-message,
.fv-plugins-turret .fv-invalid-row .fv-plugins-icon {
  color: #c00;
}

.fv-plugins-turret .fv-valid-row .form-message,
.fv-plugins-turret .fv-valid-row .fv-plugins-icon {
  color: #00b300;
}

.fv-plugins-uikit .fv-plugins-icon {
  height: 40px;
  line-height: 40px;
  top: 25px;
  width: 40px;
}

.fv-plugins-uikit.uk-form-horizontal .fv-plugins-icon {
  top: 0;
}

.fv-plugins-uikit.uk-form-horizontal .fv-plugins-icon-check {
  top: -11px;
}

.fv-plugins-uikit.uk-form-stacked .fv-plugins-icon-check {
  top: 15px;
}

.fv-plugins-uikit.uk-form-stacked .fv-no-label .fv-plugins-icon {
  top: 0;
}

.fv-plugins-uikit.uk-form-stacked .fv-no-label .fv-plugins-icon-check {
  top: -11px;
}

.fv-plugins-wizard--step {
  display: none;
}

.fv-plugins-wizard--active {
  display: block;
}

html {
  visibility: visible;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

dl,
ol,
ul {
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  border-style: none;
}

img,
svg {
  vertical-align: middle;
}

svg {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[type="button"],
[type="reset"],
[type="submit"],
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container {
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1250px;
    /* margin-bottom: 50px; */
  }
}

@media (min-width: 1880px) {
  .container {
    max-width: 1450px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -32px;
  margin-left: -32px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1,
.col-auto {
  -webkit-box-flex: 0;
}

.col-1 {
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-2,
.col-3 {
  -webkit-box-flex: 0;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-4,
.col-5 {
  -webkit-box-flex: 0;
}

.col-5 {
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-6,
.col-7 {
  -webkit-box-flex: 0;
}

.col-7 {
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-8,
.col-9 {
  -webkit-box-flex: 0;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-10,
.col-11 {
  -webkit-box-flex: 0;
}

.col-11 {
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1880px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out,
    -o-transform 0.3s ease-out;
  -webkit-transform: translateY(-50px);
  -o-transform: translateY(-50px);
  transform: translateY(-50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  -o-transform: none;
  transform: none;
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered:before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1280px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner:after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: -o-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out,
    -o-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  -o-transform: none;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  -o-transition: 0s 0.6s opacity;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  -o-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0e76bc !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0e76bcc2 !important;
}

.bg-secondary {
  background-color: #0e76bcc2 !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #0e76bcc2 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.4rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
}

.rounded-right,
.rounded-top {
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.4rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1280px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1880px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive:before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column,
.flex-row {
  -webkit-box-direction: normal !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse,
.flex-row-reverse {
  -webkit-box-direction: reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column,
  .flex-sm-row {
    -webkit-box-direction: normal !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column,
  .flex-md-row {
    -webkit-box-direction: normal !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column,
  .flex-lg-row {
    -webkit-box-direction: normal !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1280px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column,
  .flex-xl-row {
    -webkit-box-direction: normal !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1880px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xxl-column,
  .flex-xxl-row {
    -webkit-box-direction: normal !important;
  }

  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1280px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1880px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1280px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1880px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1280px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1880px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0e76bc !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0e76bcc2 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.hamburger {
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  -o-transition-property: opacity, filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger.is-active:hover,
.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after,
.hamburger.is-active .hamburger-inner:before {
  background-color: #fff;
}

.hamburger-box {
  width: 12px;
  height: 10px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  width: 12px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform, -o-transform;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

.hamburger-inner:after,
.hamburger-inner:before {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -4px;
}

.hamburger-inner:after {
  bottom: -4px;
}

.hamburger--spring .hamburger-inner {
  top: 1px;
  -webkit-transition: background-color 0s linear 0.13s;
  -o-transition: background-color 0s 0.13s linear;
  transition: background-color 0s linear 0.13s;
}

.hamburger--spring .hamburger-inner:before {
  top: 4px;
  -webkit-transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -o-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -o-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner:after {
  top: 8px;
  -webkit-transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -o-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -o-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: 0.22s;
  -o-transition-delay: 0.22s;
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s,
    -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s,
    -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -o-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -o-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s,
    transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s,
    transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s,
    -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s,
    -o-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: translate3d(0, 4px, 0) rotate(45deg);
  transform: translate3d(0, 4px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -o-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -o-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s,
    -webkit-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s,
    -o-transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: translate3d(0, 4px, 0) rotate(-45deg);
  transform: translate3d(0, 4px, 0) rotate(-45deg);
}

.fade,
.slide-up,
.two-parts:after,
.two-parts:before,
.zoom-in {
  -webkit-transition-duration: 1.5s;
  -o-transition-duration: 1.5s;
  transition-duration: 1.5s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: opacity, height, top, -webkit-transform;
  transition-property: opacity, height, top, -webkit-transform;
  -o-transition-property: opacity, height, top, -o-transform;
  transition-property: opacity, transform, height, top;
  transition-property: opacity, transform, height, top, -webkit-transform,
    -o-transform;
}

.fade {
  opacity: 0;
}

.fade.animate {
  opacity: 1;
}

.slide-up {
  -webkit-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
}

.slide-up.animate {
  -webkit-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.zoom-in {
  -webkit-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
}

.zoom-in.animate {
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.two-parts:after {
  top: 0;
  left: 0;
}

.two-parts:after,
.two-parts:before {
  content: "";
  height: 100%;
  width: 50%;
  position: absolute;
  background-color: #fff;
}

.two-parts:before {
  bottom: 0;
  right: 0;
}

.two-parts.animate:after,
.two-parts.animate:before {
  height: 0;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .two-parts:after,
  .two-parts:before {
    display: none;
  }
}

section.error404 .alert-warning {
  background-color: transparent;
  border-color: transparent;
  margin-top: 50px;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
  text-decoration: underline;
}

.s-content a,
a,
a:active,
a:focus,
a:hover {
  color: #241f21;
}

.s-content a {
  text-decoration: none;
  margin-bottom: 0;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  background-image: none;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  background-color: transparent;
  border-bottom: 2px solid #241f21;
}

button {
  border: none;
}

::selection {
  background: #dbe7f4;
}

html {
  overflow-y: scroll !important;
}

body,
html {
  min-height: 100vh;
}

body {
  font-family: GT Walsheim, Verdana, sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.body-medium,
body {
  color: #241f21;
  font-size: 18px;
  line-height: 28px;
}

.body-medium {
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
}

.body-large {
  font-family: GT Walsheim, Verdana, sans-serif;
  font-size: 20px;
  line-height: 32px;
}

@media (min-width: 768px) {
  .body-large {
    font-size: 20px;
    line-height: 32px;
  }
}

.body-small-team {
  font-family: GT Walsheim, Verdana, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .body-small-team {
    font-size: 16px;
    line-height: 20px;
  }
}

footer {
  margin-top: auto;
}

.c-heading--h1,
.c-heading--h1-l,
.c-heading--h1-xl,
.c-heading--h2,
.c-heading--h3,
.c-heading--h5,
.c-heading--h6,
.heading-five,
.heading-one,
.heading-one-l,
.heading-one-xl,
.heading-six,
.heading-three,
.heading-two,
h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  font-weight: 400;
}

.c-heading--h1,
.heading-one,
h1 {
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .c-heading--h1,
  .heading-one,
  h1 {
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 64px;
  }
}

.c-heading--h2,
.heading-two,
h2 {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .c-heading--h2,
  .heading-two,
  h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  }
}

.c-heading--h3,
.heading-three,
h3 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .c-heading--h3,
  .heading-three,
  h3 {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 8px;
  }
}

.c-heading--h4,
.c-heading--h5,
.c-heading--h6,
.heading-five,
.heading-four,
.heading-six,
h4,
h5,
h6 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .c-heading--h4,
  .c-heading--h5,
  .c-heading--h6,
  .heading-five,
  .heading-four,
  .heading-six,
  h4,
  h5,
  h6 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

p {
  color: #241f21;
}

section ol,
section ul {
  padding-left: 18px;
}

section ol li,
section ul li {
  padding-left: 5px;
  color: #241f21;
}

div + .buttons {
  margin-top: 20px;
}

@media (min-width: 992px) {
  div + .buttons {
    margin-top: 35px;
  }
}

.card-title {
  color: #241f21;
  font-size: 20px;
  line-height: 24px;
}

.card-title,
.card-title--small {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
}

.card-title--small {
  font-size: 18px;
  line-height: 28px;
}

.card-title--large {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  font-size: 20px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .card-title--large {
    font-size: 24px;
    line-height: 32px;
  }
}

.strong {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  font-weight: 400;
}

.subtitle {
  color: #241f21;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 8px;
}

.subtitle--small {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  font-size: 12px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .subtitle {
    font-size: 14px;
    line-height: 24px;
  }

  .subtitle--small {
    font-size: 12px;
    line-height: 20px;
  }
}

.backtoblog a {
  text-decoration: none;
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
}

.content-subtitle {
  line-height: 30px;
  margin-bottom: 5px;
}

.content-subtitle,
.read-more-label {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  font-size: 16px;
}

.read-more-label {
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
}

.read-more-label .arrow {
  width: 9px;
  height: 10px;
  display: inline-block;
  position: relative;
  margin: 0 3px;
}

.read-more-label .arrow span {
  top: 5px;
  position: absolute;
  width: 6px;
  height: 2px;
  background-color: #000;
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.read-more-label .arrow span:first-of-type {
  left: 0;
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.read-more-label .arrow span:last-of-type {
  right: 0;
}

.read-more-label .arrow.active span:first-of-type,
.read-more-label .arrow span:last-of-type {
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.read-more-label .arrow.active span:last-of-type {
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.label {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  background-color: #0e76bc;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  padding: 4px 7px;
}

@-moz-document url-prefix() {
  .label {
    padding: 3px 7px 4px;
  }
}

.label + .label {
  margin-left: 10px;
}

.text-color-light .c-heading--h1,
.text-color-light .c-heading--h2,
.text-color-light .c-heading--h3,
.text-color-light .c-heading--h4,
.text-color-light .c-heading--h5,
.text-color-light .c-heading--h6,
.text-color-light .heading-five,
.text-color-light .heading-four,
.text-color-light .heading-one,
.text-color-light .heading-six,
.text-color-light .heading-three,
.text-color-light .heading-two,
.text-color-light a:not(.button),
.text-color-light h1,
.text-color-light h2,
.text-color-light h3,
.text-color-light h4,
.text-color-light h5,
.text-color-light h6,
.text-color-light ol li,
.text-color-light p,
.text-color-light span:not(.c-button__label),
.text-color-light ul li {
  color: #fff;
}

.text-color-light a:not(.button).active,
.text-color-light a:not(.button):focus,
.text-color-light a:not(.button):hover {
  color: #fff;
  background-color: transparent;
}

.text-color-light .subtitle {
  color: #fff;
  background-color: #0e76bc;
  padding: 0 5px;
  margin-bottom: 20px;
}

@-moz-document url-prefix() {
  .text-color-light .subtitle {
    padding: 1px 5px 0;
  }
}

.text-color-light .button--no-bg {
  color: #fff !important;
}

@media (min-width: 768px) {
  .text-color-light .subtitle {
    padding: 0 10px;
  }
}

ol + h2,
ol + h3,
ol + h4,
ol + h5,
ol + h6,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6,
ul + h2,
ul + h3,
ul + h4,
ul + h5,
ul + h6 {
  margin-top: 16px;
}

:focus {
  outline: none !important;
}

input,
textarea {
  font-size: 16px !important;
  line-height: 20px !important;
  border-radius: 0;
}

.subtitle + h2 {
  margin-top: 0;
}

.hide,
.js-hide {
  display: none !important;
}

.js-show,
.show {
  display: block !important;
}

.hidden,
.js-hidden {
  visibility: hidden !important;
}

.js-visible,
.visible {
  visibility: visible !important;
}

.has-no-margin {
  margin: 0 !important;
}

.js-has-shadow {
  border: 1px solid #dbe5dc;
}

.js-has-no-shadow {
  border: none !important;
}

section {
  position: relative;
}

section.s-content-image:not(.section--bg-color) + section.s-featured-related,
section:not(.section--bg-color):not(.s-content-image):not(.s-page-header)
  + section:not(.section--bg-color):not(.s-content-image) {
  margin-top: 0;
  padding: 5em 0;
}

section.s-content-image:not(.section--bg-color)
  + section.s-featured-related
  .container:first-child:before,
section:not(.section--bg-color):not(.s-content-image):not(.s-page-header)
  + section:not(.section--bg-color):not(.s-content-image)
  .container:first-child:before {
  margin-bottom: 0;
  height: 1px;
  background-color: #dbe7f4;
  content: "";
  display: none;
  width: 100%;
}

@media (min-width: 768px) {
  section.s-content-image:not(.section--bg-color)
    + section.s-featured-related
    .container:first-child:before,
  section:not(.section--bg-color):not(.s-content-image):not(.s-page-header)
    + section:not(.section--bg-color):not(.s-content-image)
    .container:first-child:before {
    margin-bottom: 80px;
  }
}

@media (min-width: 576px) {
  .row:not(.no-gutters) {
    margin-right: -10px;
    margin-left: -10px;
  }

  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) [class^="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  .row:not(.no-gutters) {
    margin-right: -15px;
    margin-left: -15px;
  }

  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) [class^="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .row:not(.no-gutters) {
    margin-right: -15px;
    margin-left: -15px;
  }

  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) [class^="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1280px) {
  .row:not(.no-gutters) {
    margin-right: 0px;
    margin-left: -60px;
  }

  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) [class^="col-"] {
    padding-right: 0px;
    padding-left: 60px;
  }
}

@media (min-width: 1880px) {
  .row:not(.no-gutters) {
    margin-right: -30px;
    margin-left: -30px;
  }

  .row:not(.no-gutters) > .col,
  .row:not(.no-gutters) [class^="col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 576px) {
  section.small-gutter .row:not(.no-gutters) {
    margin-right: -10px;
    margin-left: -10px;
  }

  section.small-gutter .row:not(.no-gutters) > .col,
  section.small-gutter .row:not(.no-gutters) [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  section.small-gutter .row:not(.no-gutters) {
    margin-right: -15px;
    margin-left: -15px;
  }

  section.small-gutter .row:not(.no-gutters) > .col,
  section.small-gutter .row:not(.no-gutters) [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  section.small-gutter .row:not(.no-gutters) {
    margin-right: -15px;
    margin-left: -15px;
  }

  section.small-gutter .row:not(.no-gutters) > .col,
  section.small-gutter .row:not(.no-gutters) [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1280px) {
  section.small-gutter .row:not(.no-gutters) {
    margin-right: -15px;
    margin-left: -15px;
  }

  section.small-gutter .row:not(.no-gutters) > .col,
  section.small-gutter .row:not(.no-gutters) [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1880px) {
  section.small-gutter .row:not(.no-gutters) {
    margin-right: -15px;
    margin-left: -15px;
  }

  section.small-gutter .row:not(.no-gutters) > .col,
  section.small-gutter .row:not(.no-gutters) [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media (max-width: 360px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 991.98px) {
  .container {
    width: calc(100% - 25px);
    max-width: none;
  }
}

[class*="section-number"] {
  margin: 64px 0;
}

.where-we-work-section {
  padding: 5em 0;
  background-color: #1075bc2b;
}

@media (min-width: 768px) {
  [class*="section-number"] {
    margin: 80px 0;
  }
}

.section--bg-img {
  padding: 64px 0;
  margin: 0;
}

.section-number-5 {
  margin-bottom: 0 !important;
}

.section--bg-color {
  padding: 64px 0;
  margin: 0 !important;
}

@media (min-width: 768px) {
  .section--bg-img {
    padding: 80px 0;
    margin: 0;
  }

  .section--bg-color {
    padding: 80px 0;
    margin: 0 !important;
  }
}

.section-number-1 {
  margin-top: 120px;
}

.section-number-1.section--bg-color,
.section-number-1.section--bg-img {
  margin-top: 0;
}

@media (min-width: 992px) {
  .section-number-1 {
    margin-top: 170px;
  }

  .section-number-1.section--bg-color,
  .section-number-1.section--bg-img {
    margin-top: 0;
  }
}

.h-reverse-order {
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.h-reverse-order > * {
  -webkit-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

@media (min-width: 768px) {
  .h-reverse-order--row {
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .h-reverse-order--column,
  .h-reverse-order--row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: reverse;
  }

  .h-reverse-order--column {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.h-white-text,
.h-white-text * {
  color: #fff !important;
}

.pagercol {
  margin-top: 0;
  background-color: transparent;
}

.pagercol .facetwp-page {
  border-right: 1px solid #241f21;
  padding-right: 8px;
  padding-left: 8px;
  display: inline;
  background: none;
  color: #0e76bc;
  text-transform: uppercase;
}

.pagercol .facetwp-page.active {
  display: inline;
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
}

.pagercol .facetwp-page:active,
.pagercol .facetwp-page:focus,
.pagercol .facetwp-page:hover {
  background-color: transparent;
}

.pagercol .facetwp-page.prev {
  position: relative;
  border-right: none;
}

.pagercol .facetwp-page.next {
  position: relative;
}

.pagercol .facetwp-page.next.not-active,
.pagercol .facetwp-page.prev.not-active {
  pointer-events: none;
  cursor: default;
}

.pagercol .facetwp-page.next.not-active:after,
.pagercol .facetwp-page:last-child {
  border: none;
}

.pagercol .facetwp-page.button {
  border: none !important;
  margin-left: 20px;
  text-transform: capitalize;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0;
}

.c-apply {
  background-color: #eff3f5;
  padding: 35px 32px;
}

.c-apply__contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 0 22px;
  border-top: 1px solid #fff;
  margin-top: 20px;
}

.c-apply__contact .c-image img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  margin-right: 20px;
}

.c-apply__contact-label {
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  font-size: 14px;
  margin-bottom: 0;
}

.c-apply__phone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-apply__phone-icon {
  margin-top: 9px;
  margin-right: 9px;
}

.c-apply__phone-number {
  text-decoration: none;
  line-height: 32px;
  background-image: none;
}

.c-apply ol li,
.c-apply ul li {
  padding-left: 0;
}

.c-banner {
  background-color: #1075bc2b;
  padding: 30px 30px 40px 35px;
  color: #fff;
}

.c-banner__header {
  margin-bottom: 15px;
  color: #fff;
}

.c-banner__content p {
  color: #fff;
}

.c-banner__content a {
  color: #fff;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.c-banner__content a:active,
.c-banner__content a:focus,
.c-banner__content a:hover {
  background-image: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.c-button,
.nav__menu--buttons .nav-link {
  line-height: normal;
  text-decoration: none;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.c-button:focus,
.c-button:hover,
.nav__menu--buttons .nav-link:focus,
.nav__menu--buttons .nav-link:hover {
  text-decoration: none;
}

.c-button--link,
.c-button--link-with-icon {
  font-size: 16px;
}

.c-button--box-with-icon,
.c-button--link-with-icon {
  padding: 10px 35px;
}

.c-button--box-with-icon:focus .c-button__icon,
.c-button--box-with-icon:hover .c-button__icon,
.c-button--link-with-icon:focus .c-button__icon,
.c-button--link-with-icon:hover .c-button__icon {
  left: 16px;
}

.c-button--box-with-icon .c-button__icon,
.c-button--link-with-icon .c-button__icon {
  left: 8px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.c-button--box,
.nav__menu--buttons .nav-link {
  color: #fff;
  background-color: #0e76bc;
  padding: 8.5px 32px;
  border-radius: 0.4rem;
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
}

.c-button--box:focus,
.c-button--box:hover,
.nav__menu--buttons .nav-link:focus,
.nav__menu--buttons .nav-link:hover {
  color: #fff;
  background-color: #0e76bcc2;
}

.c-button--link {
  border-bottom: 2px solid transparent;
}

.c-button--link:active,
.c-button--link:focus,
.c-button--link:hover {
  background-color: transparent;
  color: #241f21;
  border-bottom-color: #0e76bc;
}

.c-button--box-min-width {
  min-width: 283px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.c-button--box-full-width,
.c-button--box-min-width {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c-button--box-full-width {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-button--box-colorone {
  background-color: #0e76bc;
  color: #fff;
  border-radius: 0.4rem;
}

.c-button--box-colorone:focus,
.c-button--box-colorone:hover {
  background-color: #0e76bcc2;
  color: #fff;
}

.c-button--box-colorone.c-button--ghost {
  border: 2px solid #0e76bc;
  color: #0e76bc;
  background-color: transparent;
}

.c-button--box-colorone.c-button--ghost:focus,
.c-button--box-colorone.c-button--ghost:hover {
  background-color: #0e76bc;
  color: #fff;
}

.c-button--box-black {
  background-color: #000;
  color: #fff;
}

.c-button--box-black:focus,
.c-button--box-black:hover {
  background-color: #1a1a1a;
  color: #fff;
}

.c-button--box-black.c-button--ghost {
  border: 2px solid #000;
  color: #000;
  background-color: transparent;
}

.c-button--box-black.c-button--ghost:focus,
.c-button--box-black.c-button--ghost:hover {
  background-color: #000;
  color: #fff;
}

.c-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-card:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.c-card__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: auto;
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-image: none;
  text-decoration: none;
  border-radius: 0.4rem;
  overflow: hidden;
}

.c-card__link:focus .c-card__icon,
.c-card__link:hover .c-card__icon {
  left: 10px;
}

.c-card__link:active,
.c-card__link:focus,
.c-card__link:hover {
  background-image: none;
  text-decoration: none;
}

.c-card__link--cover:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.c-card__inner {
  width: 100%;
  padding: 30px;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.c-card__inner--text-white {
  color: #fff;
}

.c-card__cta-text {
  font-size: 14px;
  display: inline-block;
  margin-top: 10px;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
}

.c-card__icon {
  position: relative;
  left: 0;
  -webkit-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  margin-left: 5px;
}

@media (min-width: 992px) {
  .c-card__cta-text {
    font-size: 16px;
    margin-top: 30px;
  }
}

@media (min-width: 1280px) {
  .c-card__inner {
    padding: 40px;
  }
}

.c-content-columns__header {
  margin-bottom: 40px;
}

.c-content-columns img {
  margin-bottom: 10px;
  height: 32px;
  width: auto;
}

.c-content-columns .column {
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .c-content-columns__header {
    margin-bottom: 60px;
  }

  .c-content-columns img {
    height: 40px;
  }

  .c-content-columns .column {
    margin-bottom: 0;
  }
}

.c-content img {
  max-width: 100%;
  height: auto;
}

.c-content--text-white {
  color: #fff;
}

@media (min-width: 768px) {
  .c-content--small-p p {
    font-size: 14px;
    line-height: 24px;
  }
}

.c-files .c-files__file-link {
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  text-decoration: none;
}

.c-form {
  background-color: #1075bc2b;
  padding: 35px 32px;
}

.c-form__contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 0 22px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-bottom: 25px;
  margin-top: 20px;
}

.c-form__contact picture img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  margin-right: 20px;
}

.c-form__contact-label {
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  font-size: 14px;
  margin-bottom: 0;
}

.c-form__phone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-form__phone-icon {
  margin-top: 9px;
  margin-right: 9px;
}

.c-form__phone-number {
  text-decoration: none;
  line-height: 32px;
  background-image: none;
}

.c-form ol li,
.c-form ul li {
  padding-left: 0;
}

.gform_validation_container {
  display: none !important;
}

.gform_fields > li {
  display: block;
  margin-bottom: 10px;
  clear: both;
}

.gform_fields select {
  display: block;
}

.gform_fields .gfield {
  position: relative;
}

.gform_fields,
.gform_fields ul,
.gform_fields ul li {
  padding-left: 0;
  list-style: none;
  margin-bottom: 8px;
}

.gform_wrapper input,
.gform_wrapper input:not([type="button"]),
.gform_wrapper input:not([type="checkbox"]),
.gform_wrapper input:not([type="file"]),
.gform_wrapper input:not([type="image"]),
.gform_wrapper input:not([type="radio"]),
.gform_wrapper input:not([type="submit"]),
.gform_wrapper textarea {
  /* width: 100%; */
  padding: 13px 10px 14px;
  font-size: 16px;
  line-height: normal;
  border: 0;
}

.gform_wrapper ol li,
.gform_wrapper ul li {
  padding-left: 0;
}

.gform_wrapper label {
  margin-bottom: 0;
}

@media screen and (min-width: 601px) {
  .gform_fields .gfmc-column {
    float: left;
    clear: none;
  }

  .gform_fields .gfmc-column[class$="1-of-2"] {
    padding-right: 5px;
  }

  .gform_fields .gfmc-column[class$="2-of-2"] {
    padding-left: 5px;
  }
}

.form__item {
  position: relative;
}

.form__item input,
.form__item textarea {
  border: 2px solid #eff3f5 !important;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: block;
  border-radius: 0.4rem;
}

.form__item input.valid,
.form__item textarea.valid {
  border: 2px solid #0e76bc !important;
}

.form__item input.invalid,
.form__item textarea.invalid {
  border: 2px solid #da8080 !important;
}

.form__item .gfield_label {
  position: absolute;
  pointer-events: none;
  color: #241f21;
  font-size: 16px;
  padding: 4px 8px;
  z-index: 1;
  font-weight: 400;
  top: 8px;
  left: 8px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form__item span.gfield_required {
  color: #241f21;
}

.form__item--floating-label input,
.form__item--floating-label textarea {
  border-color: #eff3f5 !important;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #241f21;
}

.form__item--floating-label .gfield_label {
  font-size: 14px;
  line-height: 12px;
  color: #241f21;
  top: -8px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form__item--floating-label span.gfield_required {
  color: #fff;
}

.form__item input:-webkit-autofill,
.form__item input:-webkit-autofill:focus,
.form__item input:-webkit-autofill:hover,
.form__item select:-webkit-autofill,
.form__item select:-webkit-autofill:focus,
.form__item select:-webkit-autofill:hover,
.form__item textarea:-webkit-autofill,
.form__item textarea:-webkit-autofill:focus,
.form__item textarea:-webkit-autofill:hover {
  border: none;
  -webkit-text-fill-color: #241f21;
  -webkit-box-shadow: 0 0 0 1000px #1075bc2b inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.form__item .gfmc-column {
  display: block;
}

@media (min-width: 992px) {
  .form__item-tooltip {
    position: relative;
    display: inline-block;
    z-index: 2;
  }

  .form__item-tooltip-icon {
    line-height: 20px;
    font-size: 12px;
    width: 18px;
    height: 18px;
  }

  .form__item-tooltip-text {
    left: -400%;
  }

  .form__item-tooltip-text:after {
    left: 70.5%;
  }
}

.validation_error {
  display: none !important;
}

.gfield_description.validation_message {
  display: none;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 20px;
  top: 10px;
  width: auto;
}

.fv-plugins-tooltip {
  z-index: 1 !important;
}

.fv-plugins-tooltip--hide {
  left: 9999999px !important;
}

.fv-plugins-tooltip .fv-plugins-tooltip__content {
  bottom: 45px;
  right: 40px;
  display: block;
  max-width: 260px;
}

.fv-plugins-tooltip .fv-plugins-tooltip__content:before {
  left: calc(50% + 40px) !important;
}

@media (min-width: 992px) {
  .fv-plugins-tooltip .fv-plugins-tooltip__content {
    max-width: 330px;
    right: 0;
  }

  .fv-plugins-tooltip .fv-plugins-tooltip__content:before {
    left: 50% !important;
  }
}

.fv-plugins-icon {
  font-weight: 700;
  color: #fff;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 27px;
  font-size: 14px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 12px !important;
  right: 10px !important;
  display: none;
}

.fv-plugins-icon:after {
  display: block;
  position: relative;
  content: "";
  background-repeat: no-repeat;
}

.fv-plugins-icon--valid {
  background: #0e76bc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: default !important;
}

.fv-plugins-icon--invalid {
  background: #da8080;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fv-plugins-message-container {
  display: none;
}

.form__item-tooltip {
  position: relative;
  display: inline-block;
  z-index: 2;
}

.form__item-tooltip-icon {
  cursor: default;
  background: #e63439;
  font-weight: 700;
  color: #fff;
  text-align: center;
  display: block;
  line-height: 27px;
  font-size: 14px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.form__item-tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #bdc8cc;
  color: #000 !important;
  position: absolute;
  display: -webkit-box;
  z-index: 1;
  bottom: 150%;
  left: -260%;
  margin-left: -60px;
  opacity: 0;
  padding: 15px;
  text-align: center;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.form__item-tooltip-text:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 68.5%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #bdc8cc;
}

.form__item-tooltip--active .form__item-tooltip-text,
.form__item-tooltip:hover .form__item-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.gfield_date_dropdown_day,
.gfield_date_dropdown_month,
.gfield_date_dropdown_year,
.ginput_container_select {
  position: relative;
}

.gfield_date_dropdown_day select,
.gfield_date_dropdown_month select,
.gfield_date_dropdown_year select,
.ginput_container_select select {
  display: none;
}

.gfield_date_dropdown_day .select__selected,
.gfield_date_dropdown_month .select__selected,
.gfield_date_dropdown_year .select__selected,
.ginput_container_select .select__selected {
  background-color: #fff;
}

.gfield_date_dropdown_day .select__selected:after,
.gfield_date_dropdown_month .select__selected:after,
.gfield_date_dropdown_year .select__selected:after,
.ginput_container_select .select__selected:after {
  position: absolute;
  content: "";
  top: 20px;
  right: 20px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #1075bc2b;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.gfield_date_dropdown_day .select__selected.select__arrow--active,
.gfield_date_dropdown_month .select__selected.select__arrow--active,
.gfield_date_dropdown_year .select__selected.select__arrow--active,
.ginput_container_select .select__selected.select__arrow--active {
  border-bottom: 1px solid #72b17c;
}

.gfield_date_dropdown_day .select__selected.select__arrow--active:after,
.gfield_date_dropdown_month .select__selected.select__arrow--active:after,
.gfield_date_dropdown_year .select__selected.select__arrow--active:after,
.ginput_container_select .select__selected.select__arrow--active:after {
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 16px;
}

.gfield_date_dropdown_day .select__items div,
.gfield_date_dropdown_day .select__selected,
.gfield_date_dropdown_month .select__items div,
.gfield_date_dropdown_month .select__selected,
.gfield_date_dropdown_year .select__items div,
.gfield_date_dropdown_year .select__selected,
.ginput_container_select .select__items div,
.ginput_container_select .select__selected {
  color: #241f21;
  padding: 8px 16px 6px;
  border: 1px solid transparent;
  cursor: pointer;
}

.gfield_date_dropdown_day .select__items,
.gfield_date_dropdown_month .select__items,
.gfield_date_dropdown_year .select__items,
.ginput_container_select .select__items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 101;
  overflow-y: scroll;
  max-height: 210px;
}

.gfield_date_dropdown_day .select__items--hidden,
.gfield_date_dropdown_month .select__items--hidden,
.gfield_date_dropdown_year .select__items--hidden,
.ginput_container_select .select__items--hidden {
  display: none;
}

.gfield_date_dropdown_day .select__items div:first-child,
.gfield_date_dropdown_month .select__items div:first-child,
.gfield_date_dropdown_year .select__items div:first-child,
.ginput_container_select .select__items div:first-child {
  opacity: 0.5;
}

.gfield_date_dropdown_day .select .same-as-selected,
.gfield_date_dropdown_day .select__items div:hover,
.gfield_date_dropdown_month .select .same-as-selected,
.gfield_date_dropdown_month .select__items div:hover,
.gfield_date_dropdown_year .select .same-as-selected,
.gfield_date_dropdown_year .select__items div:hover,
.ginput_container_select .select .same-as-selected,
.ginput_container_select .select__items div:hover {
  background-color: #72b17c;
}

@media (min-width: 576px) {
  .gfield_date_dropdown_day,
  .gfield_date_dropdown_month,
  .gfield_date_dropdown_year,
  .ginput_container_select {
    max-width: 300px;
  }
}

.gfield_label + .ginput_container_checkbox .fv-plugins-icon {
  top: -30px !important;
}

.ginput_container_checkbox li {
  position: relative;
  padding-left: 40px;
}

.ginput_container_checkbox li label {
  margin-left: 30px;
}

.ginput_container_checkbox li label:after {
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.ginput_container_checkbox input {
  opacity: 0;
  outline: none;
  z-index: 100;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.ginput_container_checkbox input + label {
  cursor: pointer;
}

.ginput_container_checkbox input:checked + label:before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 20px;
  border: solid #0e76bc;
  border-width: 0 3px 3px 0;
  left: 8px;
  top: -5px;
  -webkit-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  transform: rotate(40deg);
  opacity: 1;
  z-index: 1;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.gfield_label + .ginput_container_radio .fv-plugins-icon {
  top: 2px !important;
}

.ginput_container_radio li {
  display: inline-block;
  margin-right: 20px;
}

.ginput_container_radio li:last-child {
  margin-right: 0;
}

.ginput_container_radio input {
  display: none;
}

.ginput_container_radio input + label {
  position: relative;
}

.ginput_container_radio input + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 8px 4px 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
}

.ginput_container_radio input + label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.ginput_container_radio input:checked + label:before {
  background-color: #0e76bc;
  border: 4px solid #fff;
  width: 20px;
  height: 20px;
}

@media (min-width: 768px) {
  .ginput_container_radio input + label:after {
    top: 3px;
  }
}

.ginput_container_date .clear-multi .ginput_container {
  margin-bottom: 10px;
}

.ginput_container_date .gfield_date_dropdown_day {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

@media (min-width: 576px) {
  .ginput_container_date .clear-multi {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .ginput_container_date .clear-multi .ginput_container {
    width: 33%;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .ginput_container_date .clear-multi .ginput_container:last-child {
    margin-right: 0;
  }
}

.c-header__subheading {
  margin-bottom: 5px;
}

.c-header__subheading--large {
  margin-bottom: 15px;
}

.c-header__heading--large {
  padding-bottom: 25px;
}

.c-heading {
  display: block;
  font-weight: 400;
}

.c-hotspots__dots {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.c-hotspots__dot {
  display: inline-block;
  margin-left: -2px;
  margin-top: -2px;
  width: 4px;
  height: 4px;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-box-sizing: unset;
  box-sizing: unset;
}

.c-hotspots__dot--color-one {
  -webkit-animation: pulseone 1.5s ease-out infinite;
  -o-animation: pulseone 1.5s infinite ease-out;
  animation: pulseone 1.5s ease-out infinite;
  background: #0e76bc;
}

@-webkit-keyframes pulseone {
  0% {
    -webkit-box-shadow: 0 0 0 0 #0e76bc;
    box-shadow: 0 0 0 0 #0e76bc;
  }

  to {
    -webkit-box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
    box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
  }
}

@-o-keyframes pulseone {
  0% {
    box-shadow: 0 0 0 0 #0e76bc;
  }

  to {
    box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
  }
}

@keyframes pulseone {
  0% {
    -webkit-box-shadow: 0 0 0 0 #0e76bc;
    box-shadow: 0 0 0 0 #0e76bc;
  }

  to {
    -webkit-box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
    box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
  }
}

.c-hotspots__dot--color-two {
  background: #daf0df;
  -webkit-animation: pulsetwo 1.5s ease-out infinite;
  -o-animation: pulsetwo 1.5s infinite ease-out;
  animation: pulsetwo 1.5s ease-out infinite;
}

@-webkit-keyframes pulsetwo {
  0% {
    -webkit-box-shadow: 0 0 0 0 #daf0df;
    box-shadow: 0 0 0 0 #daf0df;
  }

  to {
    -webkit-box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
    box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
  }
}

@-o-keyframes pulsetwo {
  0% {
    box-shadow: 0 0 0 0 #daf0df;
  }

  to {
    box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
  }
}

@keyframes pulsetwo {
  0% {
    -webkit-box-shadow: 0 0 0 0 #daf0df;
    box-shadow: 0 0 0 0 #daf0df;
  }

  to {
    -webkit-box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
    box-shadow: 0 0 0 7px rgba(52, 106, 180, 0);
  }
}

@media (min-width: 992px) {
  .c-hotspots__dot {
    margin-left: -3px;
    margin-top: -3px;
    width: 6px;
    height: 6px;
  }
}

.c-image {
  display: inline-block;
}

.c-image--cover {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: block;
  top: 0;
  left: 0;
}

.c-image--cover .c-image__img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  font-family: object-fit\:cover;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.c-card__link:focus,
.c-card__link:hover,
.c-image--zoom-in {
  overflow: hidden;
}

.c-card__link:focus .c-image__img,
.c-card__link:hover .c-image__img,
.c-image--zoom-in:focus .c-image__img,
.c-image--zoom-in:hover .c-image__img {
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.c-image .c-image__img {
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.c-language-switcher {
  padding: 0;
  margin-bottom: 0;
  margin-top: 50px;
}

.c-language-switcher .lang-item {
  display: inline-block;
  list-style: none;
  margin: 0;
}

.c-language-switcher .lang-item:nth-child(n + 2) {
  margin-left: 15px;
}

.c-language-switcher a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  font-size: 11px;
  border-radius: 50%;
  border: 1px solid #fff;
  text-decoration: none;
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  color: #fff;
  background-image: none;
}

.c-language-switcher a:active,
.c-language-switcher a:focus,
.c-language-switcher a:hover {
  color: #0e76bc;
  background-color: #fff;
  background-image: none;
}

.c-language-switcher__current-language {
  display: none;
}

.c-language-switcher .current-lang a {
  background-color: #fff;
  color: #0e76bc;
}

.c-language-switcher--flags {
  list-style: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c-language-switcher--flags:hover {
  display: block;
}

.c-language-switcher--flags .lang-item {
  width: 100%;
  line-height: 16px;
  margin-bottom: 20px;
}

.c-language-switcher--flags .lang-item:last-child {
  margin-bottom: 0;
}

.c-language-switcher--flags a {
  text-decoration: none;
}

.c-language-switcher--flags img {
  width: 24px;
  height: auto;
}

.c-language-switcher--flags span {
  margin-left: 20px !important;
  font-size: 12px;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
}

.js-nav--expanded .c-language-switcher a,
.js-no-page-header .c-language-switcher a,
.js-site-header--fixed .c-language-switcher a {
  border-color: #241f21;
  color: #241f21;
}

.js-nav--expanded .c-language-switcher .current-lang a,
.js-nav--expanded .c-language-switcher a:hover,
.js-no-page-header .c-language-switcher .current-lang a,
.js-no-page-header .c-language-switcher a:hover,
.js-site-header--fixed .c-language-switcher .current-lang a,
.js-site-header--fixed .c-language-switcher a:hover {
  color: #0e76bc;
  background-color: #fff;
  border-color: #0e76bc;
}

@media (min-width: 992px) {
  .c-language-switcher {
    margin-left: 20px;
    margin-top: 0;
  }

  .c-language-switcher a {
    width: 26px;
    height: 26px;
    line-height: 24px;
  }

  .c-language-switcher--flags {
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    margin-left: 0;
    background: #fff;
    padding: 30px;
  }

  .c-language-switcher--flags .lang-item {
    float: left;
    width: 50%;
  }

  .c-language-switcher--flags a {
    margin-right: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }

  .c-language-switcher--flags a:hover {
    color: #0e76bc;
    background-color: #fff;
  }

  .c-language-switcher--flags img {
    min-width: 24px;
  }

  .c-language-switcher__current-language {
    display: block;
    margin-left: 20px;
    cursor: pointer;
  }

  .c-language-switcher__current-language:hover + .c-language-switcher--flags {
    display: block;
  }
}

@media (min-width: 1280px) {
  .c-language-switcher {
    margin-left: 20px;
  }
}

.c-latest-posts {
  line-height: 16px;
}

.c-latest-posts__expertise {
  font-size: 14px;
  line-height: 19.2px;
}

.c-latest-posts__expertise:after {
  content: ", ";
  position: relative;
  margin-right: 5px;
}

.c-latest-posts__expertise:last-of-type:after {
  display: none;
}

.c-latest-posts__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 15px;
}

.c-latest-posts__pub-nr {
  font-size: 14px;
  line-height: 19.2px;
}

.c-latest-posts__pub-nr:before {
  content: "|";
  margin-left: 10px;
  margin-right: 10px;
}

.c-latest-posts__job-type {
  font-size: 14px;
  line-height: 24px;
}

.c-latest-posts .card-title--small {
  margin-bottom: 10px;
  text-decoration: none;
}

.c-list-expandable__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 20px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, #0e76bc),
    color-stop(50%, #1075bc2b)
  );
  background-image: -webkit-linear-gradient(top, #0e76bc 50%, #1075bc2b 0);
  background-image: -o-linear-gradient(top, #0e76bc 50%, #1075bc2b 50%);
  background-image: linear-gradient(180deg, #0e76bc 50%, #1075bc2b 0);
  background-size: 100% 200%;
  -webkit-transition: background-position 0.8s cubic-bezier(1, 0, 0, 1);
  -o-transition: background-position 0.8s cubic-bezier(1, 0, 0, 1);
  transition: background-position 0.8s cubic-bezier(1, 0, 0, 1);
  z-index: 1;
}

.c-list-expandable__title h3 {
  text-decoration: none;
  margin-bottom: 0;
}

.c-list-expandable__title h3 a {
  text-decoration: none;
}

.c-list-expandable__title h3 a:active,
.c-list-expandable__title h3 a:focus,
.c-list-expandable__title h3 a:hover {
  color: #241f21 !important;
  background-color: transparent;
}

.c-list-expandable__title .plus {
  font-weight: 400;
  font-size: 20px;
  line-height: 32.4px;
}

.c-list-expandable__title .division-toggle {
  padding-top: 5px;
}

.c-list-expandable__content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.8s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.8s cubic-bezier(1, 0, 0, 1);
  transition: all 0.8s cubic-bezier(1, 0, 0, 1);
  position: relative;
}

.c-list-expandable__content p {
  padding: 15px 20px;
}

.c-list-expandable__content :last-child {
  margin-bottom: 0;
}

.c-list-expandable__item {
  padding: 0 0 8px;
  cursor: pointer;
  overflow: hidden;
}

.c-list-expandable__item:first-of-type {
  padding: 0 0 8px;
}

.c-list-expandable__item:hover a {
  background-color: transparent;
}

.c-list-expandable__item.extended .c-list-expandable__title {
  background-position: 0 100%;
  -webkit-transition: background-position 0.8s cubic-bezier(1, 0, 0, 1);
  -o-transition: background-position 0.8s cubic-bezier(1, 0, 0, 1);
  transition: background-position 0.8s cubic-bezier(1, 0, 0, 1);
}

.c-list-expandable__item.extended .c-list-expandable__content {
  opacity: 1;
  max-height: 2000px;
  -webkit-transition: all 0.8s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.8s cubic-bezier(1, 0, 0, 1);
  transition: all 0.8s cubic-bezier(1, 0, 0, 1);
}

.c-list-expandable__item.extended .plus {
  display: none;
}

.tcon {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 17px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 17px;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.tcon > * {
  display: block;
}

.tcon:focus,
.tcon:hover {
  outline: none;
}

.tcon::-moz-focus-inner {
  border: 0;
}

.tcon-plus {
  height: 17px;
  position: relative;
  -webkit-transform: scale(0.75);
  -o-transform: scale(0.75);
  transform: scale(0.75);
  width: 17px;
  top: 3px;
}

.tcon-plus:after,
.tcon-plus:before {
  content: "";
  border-radius: 2px;
  display: block;
  width: 85%;
  height: 10%;
  position: absolute;
  top: 37%;
  left: 8%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background: #241f21;
}

.tcon-plus:after {
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (min-width: 992px) {
  .tcon-plus {
    height: 30px;
    width: 30px;
  }
}

.tcon-plus--minus.tcon-transform:before {
  -webkit-transform: rotate(180deg) translate(0);
  -o-transform: rotate(180deg) translate(0);
  transform: rotate(180deg) translate(0);
  width: 62%;
}

.tcon-plus--minus.tcon-transform:after {
  -webkit-transform: rotate(-180deg) translate(-37.5%);
  -o-transform: rotate(-180deg) translate(-37.5%);
  transform: rotate(-180deg) translate(-37.5%);
  width: 62%;
}

.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tcon-visuallyhidden:active,
.tcon-visuallyhidden:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.c-map--no-popup .gm-style div,
.c-map--no-popup .gm-style img {
  cursor: -webkit-grab;
  cursor: grab;
}

.c-map__popup {
  margin-bottom: 0;
}

.c-map__popup-address-1,
.c-map__popup-address-2,
.c-map__popup-heading {
  display: block;
}

.c-map__popup-heading {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
}

.nav__menu-item-drop-down {
  padding: 2px;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 8px;
  border: solid #241f21;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-mega-menu {
  margin: 0;
  padding: 0;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c-mega-menu__column {
  margin-bottom: 10px;
}

.c-mega-menu__heading {
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
}

.c-mega-menu__sub-menu {
  padding-left: 0;
}

.c-mega-menu__sub-menu-item {
  list-style: none;
}

.c-mega-menu__sub-menu-link {
  position: relative;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  text-decoration: none;
}

.c-mega-menu__sub-menu-link-current .c-mega-menu__sub-menu-link,
.c-mega-menu__sub-menu-link:active,
.c-mega-menu__sub-menu-link:focus,
.c-mega-menu__sub-menu-link:hover {
  background-color: transparent;
  text-decoration: none;
  color: #241f21;
  border-bottom: 2px solid #0e76bc;
}

@media (min-width: 992px) {
  .nav__menu-item-drop-down {
    border-color: #fff;
  }

  .nav__menu--mega-menu .nav__menu-link--top-level {
    font-size: 15px;
    color: #fff;
  }

  .nav__menu--mega-menu .nav__menu-link--top-level:hover {
    color: #fff;
  }

  .nav__menu--mega-menu .nav-item {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: normal;
  }

  .nav__menu--mega-menu .nav-link:hover {
    padding-top: 2px;
  }

  .c-mega-menu {
    background: #fff;
    position: absolute;
    top: 90px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: calc(200vw - 100%);
    padding: 30px calc(100vw - 100%) 100px;
    left: calc(-100vw + 100%);
    display: none;
    margin-left: 0;
  }

  .nav-item:hover .c-mega-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .c-mega-menu__sub-menu {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .c-mega-menu__sub-menu-item {
    width: 100%;
  }

  .nav-item .c-mega-menu__sub-menu-link {
    color: #241f21;
    padding: 0;
  }

  .nav-item
    .c-mega-menu__sub-menu-link-current
    .nav-item
    .c-mega-menu__sub-menu-link,
  .nav-item .c-mega-menu__sub-menu-link:active,
  .nav-item .c-mega-menu__sub-menu-link:focus,
  .nav-item .c-mega-menu__sub-menu-link:hover {
    color: #241f21;
    background: transparent;
  }

  .nav-item
    .c-mega-menu__sub-menu-link-current
    .nav-item
    .c-mega-menu__sub-menu-link:before,
  .nav-item .c-mega-menu__sub-menu-link:active:before,
  .nav-item .c-mega-menu__sub-menu-link:focus:before,
  .nav-item .c-mega-menu__sub-menu-link:hover:before {
    top: 100%;
  }

  .js-nav--expanded .nav__menu--mega-menu .nav__menu-link--top-level:hover,
  .js-no-page-header .nav__menu--mega-menu .nav__menu-link--top-level:hover,
  .js-site-header--fixed
    .nav__menu--mega-menu
    .nav__menu-link--top-level:hover {
    color: #241f21;
  }
}

.modal .close-custom {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 1;
}

.modal-open {
  height: 100vh;
  padding-right: 0 !important;
}

.modal-content {
  border-radius: 0;
  border: 0;
}

.modal-body {
  padding: 0;
  background-color: #1075bc2b;
}

.c-post-info__item {
  margin-bottom: 17px;
}

.c-post-info__item .card-title--small {
  display: block;
}

.c-post-info__item a,
.c-post-info__item p {
  font-size: 14px;
}

.c-post-info__item-author {
  display: inline;
}

.c-post-info__item-author:after {
  content: ",";
  position: relative;
}

.c-post-info__item-author:last-of-type:after {
  display: none;
}

.c-post-info__item-expertise:after {
  content: ",";
  position: relative;
}

.c-post-info__item-expertise:last-of-type:after {
  display: none;
}

.c-post-summary {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid rgba(114, 177, 124, 0.4);
}

.c-post-summary .c-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-post-summary .c-image img {
  max-width: 130px;
  width: 100%;
  height: auto;
}

.c-post-summary .c-heading--h3,
.c-post-summary .heading-three {
  margin-bottom: 5px;
}

.c-post-summary .c-heading--h3 a,
.c-post-summary .heading-three a {
  text-decoration: none;
}

.c-post-summary__type {
  margin-bottom: 0;
}

.c-post-summary__external-icon {
  margin-right: 10px;
  margin-bottom: 3px;
}

.c-post-summary__excerpt {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 24px;
}

.c-post-summary__categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c-post-summary__pub-nr {
  margin-left: 10px;
  font-size: 14px;
  line-height: 16px;
}

.c-post-summary__label {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .c-post-summary__pub-nr {
    margin-left: 20px;
  }
}

.c-quote__author,
.c-quote__author-info,
.c-quote__author-position,
.c-quote__text {
  display: block;
  margin-top: 30px;
}

.c-quote__author {
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  font-size: 15px;
  line-height: 20px;
}

.c-quote__author-position {
  font-size: 14px;
  line-height: 24px;
}

.c-quote__text {
  font-size: 18px;
  line-height: 28px;
}

.c-quote__text:after,
.c-quote__text:before {
  display: none;
}

.c-quote__author-info {
  font-size: 16px;
  line-height: 30px;
}

.c-quote__author-info:before {
  content: "\2014";
  color: #0e76bc;
  padding-right: 10px;
}

@media (min-width: 992px) {
  .c-quote__author {
    font-family: GT-Walsheim-Regular, Verdana, sans-serif;
    font-size: 15px;
    line-height: 20px;
  }

  .c-quote__author-position {
    font-size: 14px;
    line-height: 24px;
  }

  .c-quote__text {
    font-size: 25px;
    line-height: 40px;
  }

  .c-quote__author-info {
    font-size: 16px;
    line-height: 30px;
  }
}

.c-search-bar {
  background-color: #eff3f5;
  display: none;
  height: 55px;
  width: 100%;
  top: -55px;
  left: 0;
  z-index: 1000;
}

.c-search-bar,
.c-search-bar__container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-search-bar__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-search-bar__form {
  width: 100%;
}

.c-search-bar__form-container {
  margin-bottom: 0;
}

.c-search-bar__form-input {
  background: none;
  border-radius: 0;
  border: none !important;
  color: #241f21 !important;
  padding-left: 0 !important;
  padding-right: 10px !important;
  margin-top: 0;
  font-size: 15px;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  line-height: 16px;
}

.c-search-bar__form-input::-webkit-input-placeholder {
  color: #241f21;
}

.c-search-bar__form-input:focus {
  background: none;
}

.c-search-bar__search-icon {
  margin-right: 15px;
}

.c-search-bar .search-field::-webkit-search-cancel-button {
  position: relative;
}

.c-search-bar__toggle {
  margin-top: 40px;
}

.c-search-bar__toggle-link {
  background-image: none;
}

.c-search-bar__toggle-link-current .c-search-bar__toggle-link,
.c-search-bar__toggle-link:active,
.c-search-bar__toggle-link:focus,
.c-search-bar__toggle-link:hover {
  background-color: transparent;
  background-image: none;
}

.c-search-bar__toggle-link:before {
  content: none !important;
}

.c-search-bar__close-link {
  background-image: none;
}

.c-search-bar__close-link:active,
.c-search-bar__close-link:focus,
.c-search-bar__close-link:hover {
  background-color: transparent;
  background-image: none;
}

.c-search-bar__close-icon {
  margin-left: 15px;
  margin-top: 1px;
  width: 13px;
  height: 13px;
}

.js-nav--expanded .c-search-bar__toggle-icon,
.js-no-page-header .c-search-bar__toggle-icon,
.js-site-header--fixed .c-search-bar__toggle-icon {
  -webkit-box-sizing: unset;
  box-sizing: unset;
}

.js-nav--expanded .c-search-bar__toggle-icon g,
.js-no-page-header .c-search-bar__toggle-icon g,
.js-site-header--fixed .c-search-bar__toggle-icon g {
  stroke: #241f21;
}

@media (min-width: 992px) {
  .c-search-bar__toggle {
    margin-left: 20px;
    margin-top: 0;
  }
}

.c-simple-card {
  position: relative;
  background-color: #eff3f5;
}

.c-simple-card--right-top-hover:focus .c-simple-card__inner,
.c-simple-card--right-top-hover:hover .c-simple-card__inner {
  left: 10px;
  bottom: 10px;
  border: 1px solid #bdc8cc;
  background-color: #fff;
}

.c-simple-card--right-top-hover:focus .c-simple-card__icon,
.c-simple-card--right-top-hover:hover .c-simple-card__icon {
  left: 10px;
}

.c-simple-card__link {
  display: block;
  height: 100%;
}

.c-simple-card__link,
.c-simple-card__link:active,
.c-simple-card__link:focus,
.c-simple-card__link:hover {
  background-image: none;
  text-decoration: none;
}

.c-simple-card__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  background-color: #eff3f5;
  border: 1px solid transparent;
  padding: 20px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  left: 0;
  bottom: 0;
}

.c-simple-card__content-wrapper {
  width: 100%;
}

.c-simple-card__image {
  height: 32px;
  width: auto;
  margin-right: 10px;
  margin-bottom: 5px;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.c-simple-card__header {
  margin-bottom: 5px;
}

.c-simple-card__cta-text {
  display: inline-block;
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-size: 14px;
  margin-top: 10px;
}

.c-simple-card__icon {
  position: relative;
  left: 0;
  -webkit-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  margin-left: 5px;
}

@media (min-width: 992px) {
  .c-simple-card__inner {
    padding: 30px;
  }

  .c-simple-card__image {
    height: 40px;
  }

  .c-simple-card__cta-text {
    font-size: 16px;
    font-family: GT-Walsheim-Regular, Verdana, sans-serif;
    margin-top: 30px;
  }
}

.c-social-accounts {
  margin-top: 40px;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.c-social-accounts__item {
  display: inline-block;
  margin-right: 8px;
}

.c-social-accounts__item:last-child {
  margin-right: 0;
}

.c-social-accounts__link {
  border-radius: 50%;
  -webkit-transition: background 0 linear;
  -o-transition: background 0 linear;
  transition: background 0 linear;
  width: 32px;
  text-decoration: none;
}

.c-social-accounts__link:active,
.c-social-accounts__link:focus,
.c-social-accounts__link:hover {
  background: transparent;
  text-decoration: none;
  opacity: 0.7;
}

.c-social-accounts__icon {
  width: 32px;
  height: 32px;
}

.c-social-accounts__icon--rounded {
  border-radius: 10%;
}

.c-social-accounts__icon--circle {
  border-radius: 50%;
}

@media (min-width: 992px) {
  .c-social-accounts {
    margin-top: 0;
  }

  .c-social-accounts__link {
    width: 25px;
  }

  .c-social-accounts__icon {
    width: 25px;
    height: 25px;
  }
}

.c-subheading {
  display: inline-block;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 8px;
}

.c-subheading--small {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  font-size: 12px;
  line-height: 20px;
}

.c-subheading--background {
  background-color: #72b17c;
  padding: 2px 8px;
}

.c-submenu__list {
  list-style: none;
  padding: 0 0 20px;
  z-index: 20;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c-submenu__list-item {
  padding: 0;
}

.c-submenu__link {
  text-decoration: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  line-height: normal;
  padding-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 0;
  display: inline-block;
  border-bottom: 2px solid transparent;
}

.c-submenu__link:active,
.c-submenu__link:focus,
.c-submenu__link:hover {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  background-color: none;
  background-image: none;
  text-decoration: none;
  border-bottom: 2px solid #0e76bc;
}

.c-submenu__link--active {
  border-bottom: 2px solid #0e76bc;
}

.c-submenu__link:last-of-type {
  margin-bottom: 10px;
}

.c-submenu__dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border-radius: 0;
  padding: 15px 0;
  border-width: 0;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
}

.c-submenu__dropdown.js-open {
  margin-bottom: 0;
}

.c-submenu__dropdown.js-open__arrow {
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.15s ease;
  transition: -webkit-transform 0.15s ease;
  -o-transition: -o-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease,
    -o-transform 0.15s ease;
}

@media (min-width: 768px) {
  .c-submenu__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 10px 0 0;
  }

  .c-submenu__list-item {
    margin: 0 20px 10px 0;
  }

  .c-submenu__dropdown {
    display: none;
  }
}

.s-blog-overview__header {
  margin-bottom: 40px;
}

.s-blog-overview__image img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  font-family: object-fit\:cover;
}

.s-blog-overview__filter-wrap {
  margin-bottom: 25px;
}

.s-blog-overview__filter .facetwp-facet-categories {
  background-color: #eff3f5;
  padding-top: 5px;
  padding-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.s-blog-overview__filter .facetwp-radio {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  display: block;
  background: none;
  color: #241f21;
  line-height: 17px;
  padding-left: 0;
  position: relative;
  margin: 0 20px 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid transparent;
}

.s-blog-overview__filter .facetwp-radio:last-child {
  margin-right: 0;
}

.s-blog-overview__filter .facetwp-radio:last-child:after {
  content: none;
}

.s-blog-overview__filter .facetwp-radio.checked,
.s-blog-overview__filter .facetwp-radio:active,
.s-blog-overview__filter .facetwp-radio:focus,
.s-blog-overview__filter .facetwp-radio:hover {
  border-bottom: 2px solid #000;
  background-image: none;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.s-blog-overview__filter .facetwp-overlay,
.s-blog-overview__filter span.facetwp-counter {
  display: none;
}

.s-blog-overview__filter-all {
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: none;
  color: #241f21;
  line-height: 17px;
  padding-left: 0;
  position: relative;
  background-image: none;
  margin: 0 20px 5px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.s-blog-overview__filter-all.active,
.s-blog-overview__filter-all:active,
.s-blog-overview__filter-all:focus,
.s-blog-overview__filter-all:hover {
  border-bottom: 2px solid #000;
  background-image: none;
}

.s-blog-overview__categories-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border-radius: 0;
  padding: 13px 20px;
  border-width: 0;
  color: #241f21;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  background-color: #eff3f5;
}

.s-blog-overview__categories-dropdown .arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  -webkit-transition: -webkit-transform 0.15s ease;
  transition: -webkit-transform 0.15s ease;
  -o-transition: -o-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease,
    -o-transform 0.15s ease;
}

.s-blog-overview__categories-dropdown.open {
  margin-bottom: 0;
  padding-bottom: 0;
}

.s-blog-overview__categories-dropdown.open .arrow {
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.15s ease;
  transition: -webkit-transform 0.15s ease;
  -o-transition: -o-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease,
    -o-transform 0.15s ease;
}

.s-blog-overview__posts .facetwp-template {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.s-blog-overview__card {
  margin-bottom: 25px;
}

.s-blog-overview__card h3 {
  margin-bottom: 0 !important;
  text-transform: none !important;
}

.s-blog-overview .facetwp-type-radio {
  margin-bottom: 0;
}

.s-blog-overview__loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 10;
}

.s-blog-overview__loading-text {
  padding: 10px 40px;
  background-color: #0e76bc;
  color: #fff;
}

@media (min-width: 768px) {
  .s-blog-overview__header {
    margin-bottom: 70px;
  }

  .s-blog-overview__posts .facetwp-template {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
  }

  .s-blog-overview__card {
    margin-bottom: 30px;
  }

  .s-blog-overview__filter-wrap {
    margin-bottom: 0;
  }

  .s-blog-overview__filter .facetwp-facet-categories {
    background-color: transparent;
    padding-bottom: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .s-blog-overview__filter-all {
    margin-right: 20px;
    margin-left: 0;
  }

  .s-blog-overview__filter .facetwp-radio {
    line-height: 17px;
  }

  .s-blog-overview .facetwp-type-radio {
    margin-bottom: 50px;
  }

  .s-blog-overview__categories-dropdown {
    display: none;
  }
}

@media (min-width: 1280px) {
  .s-blog-overview__card {
    margin-bottom: 60px;
  }
}

section.contact .left {
  margin-bottom: 20px;
}

section.contact .left h1 {
  margin-bottom: 16px;
}

section.contact .form-centre {
  margin: auto;
}

section.contact .form-description {
  text-align: center;
  margin-top: 8px;
}

section.contact p:last-of-type {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  section.contact .left {
    margin-bottom: 0;
  }
}

.s-content-columns {
  margin-bottom: 64px;
}

.s-content-columns.section--bg-color {
  background-color: #fff;
  padding-bottom: 56px;
}

.s-content-columns.section--bg-color__header {
  margin-top: 24px;
}

.s-content-columns__header {
  margin-bottom: 48px;
  text-align: center;
}

.s-content-columns__card-image {
  margin-bottom: 24px;
}

.s-content-columns__card {
  margin-top: 16px;
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .s-content-columns {
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 40px 0px 20px 0px;
    background-color: #fff;
  }

  .s-content-columns__header {
    margin-bottom: 64px;
  }

  .s-content-columns__card {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.s-content-image.section--bg-color {
  background-color: #1075bc2b;
}

.s-content-image__content {
  width: 100%;
}

.s-content-image__content p:last-of-type {
  margin-bottom: 0;
}

.s-content-image__content--aligned-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.s-content-image__content header {
  width: 100%;
}

.s-content-image__image .wp-post-image {
  width: 100%;
  height: auto;
  border-radius: 0.4rem;
}

.s-content-image__image--aligned-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.s-content-image__image ~ .s-content-image__content {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .s-content-image__image ~ .s-content-image__content {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .s-content-image__content,
  .s-content-image__image {
    padding-right: 30px;
  }
}

@media (min-width: 1280px) {
  .s-content-image__content,
  .s-content-image__image {
    padding-right: 40px;
  }
}

section.content-with-sidebar .content-left {
  margin-bottom: 20px;
}

section.content-with-sidebar .sidebar > div[class*="c-"] {
  margin-bottom: 40px;
}

section.content-with-sidebar .sidebar .body-medium {
  margin-bottom: 0;
}

section.content-with-sidebar .c-content {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  section.content-with-sidebar .content-left {
    margin-bottom: 0;
  }

  section.content-with-sidebar .content-left > div[class*="c-"] {
    margin-bottom: 40px;
  }

  section.content-with-sidebar .content-left > div[class*="c-"]:last-of-type {
    margin-bottom: 0;
  }
}

.s-featured-related__header {
  margin-bottom: 32px;
}

.s-featured-related__card,
.s-featured-related__card:last-child {
  margin-bottom: 25px;
}

.s-featured-related__card:nth-child(1n + 4) {
  display: none;
}

.s-featured-related__button {
  margin-top: 32px;
}

@media screen and (min-width: 400px) {
  .s-featured-related__posts {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.bg-my-work {
  background-color: #fff;
}

@media (min-width: 576px) {
  .s-featured-related__card {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .s-featured-related__card:nth-child(n) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 768px) {
  .s-featured-related__card,
  .s-featured-related__card:last-child {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .s-featured-related__header {
    margin-bottom: 64px;
  }

  .s-featured-related__card {
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    margin-bottom: 0;
  }

  .s-featured-related__card:last-child {
    margin-bottom: 0;
  }

  .s-featured-related__button {
    margin-top: 0px;
  }
}

.s-list-expandable {
  background-color: #1075bc2b;
}

.s-list-expandable__line {
  border-top: 2px solid #72b17c;
}

.s-map {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
}

.s-map__map {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  margin: 0;
}

.s-map__map #map {
  height: 550px;
}

@media screen and (min-width: 440px) {
  .s-map__map {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 768px) {
  .s-map__map {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .s-map__map #map {
    height: 600px;
  }
}

@media screen and (min-width: 440px) {
  .s-map__map--no-image {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.s-page-header {
  padding: 0;
  margin-top: 70px;
  margin-bottom: 0;
}

.s-page-header.page-header--no-top-margin {
  margin-top: 0;
}

.s-page-header__content,
.s-page-header__header {
  width: 100%;
}

.s-page-header .container,
.s-page-header .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.s-page-header .row {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-bottom: 0;
}

.s-page-header .row > .col-md-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.s-page-header .content {
  background-color: #d0ddf0d1;
  padding: 56px 0;
  border-radius: 0.4rem;
}

.s-page-header .content .c-content p {
  margin-bottom: 0;
}

.s-page-header h1 {
  width: 100%;
}

.s-page-header__buttons {
  margin-top: 16px;
  border-radius: 0.4rem;
  background-color: #0e76bc;
}
.s-page-header__buttons a {
  color: #fff;
}

.s-page-header__buttons:hover {
  margin-top: 16px;
  border-radius: 0.4rem;
  background-color: #0e76bcc2;
}

.s-page-header picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.s-page-header picture img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  font-family: object-fit\:cover;
}

.s-page-header__inner {
  background-color: #d0ddf0;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.s-page-header__inner .c-image--cover {
  position: static;
}

.s-page-header__carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 0;
  margin: 6px;
}

@media screen and (min-width: 650px) {
  .s-page-header .row > .col-md-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .s-page-header .row {
    width: 100%;
  }

  .s-page-header .content {
    padding: 35px 45px;
    width: 100%;
    /* text-align: justify; */
  }

  .s-page-header__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 150px 0;
    position: relative;
    width: 100%;
    height: 600px;
  }

  .s-page-header__inner .c-image--cover {
    position: absolute;
  }

  .s-page-header__inner > .container {
    background-size: cover;
    background-position: 50%;
  }

  .s-page-header__inner > .container .row {
    padding-bottom: 0;
  }

  .s-page-header--video video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .s-page-header--video {
    padding: 0;
    height: 44vw;
  }

  .s-page-header--video picture {
    display: none;
  }

  .s-page-header--video > .container {
    height: 44vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1900px) {
  .s-page-header--video,
  .s-page-header--video > .container {
    height: 856px;
  }
}

@media (min-width: 768px) and (min-width: 1280px) {
  .s-page-header--image {
    padding: 240px 0;
  }
}

@media (min-width: 768px) and (min-width: 1280px) {
  .s-page-header--short {
    padding: 140px 0;
  }
}

@media (min-width: 992px) {
  .s-page-header {
    margin-top: 80px;
  }
}

.s-publication-overview__header {
  margin-bottom: 32px;
}

.s-publication-overview__card {
  margin-top: 24px;
}

.s-publication-overview__card-image {
  margin-bottom: 0px;
}

.s-publication-overview__card-image img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

@media (min-width: 768px) {
  .s-publication-overview__card,
  .s-publication-overview__card:nth-child(n + 4) {
    margin-top: 32px;
  }

  .s-publication-overview__card-image {
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .s-publication-overview__header {
    margin-bottom: 64px;
  }

  .s-publication-overview__card,
  .s-publication-overview__card:nth-child(n + 4) {
    margin-top: 64px;
  }
}

@media (min-width: 1280px) {
  .s-publication-overview__header {
    margin-bottom: 64px;
  }

  .s-publication-overview__card:nth-child(n + 4) {
    margin-top: 64px;
  }
}

section.team .row.intro {
  padding-bottom: 45px;
  padding-top: 60px;
  max-width: 600px;
}

section.team .s-team__profile-image {
  margin-bottom: 16px;
}

section.team img {
  width: 100%;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

section.team p:last-of-type {
  margin-bottom: 0;
}

section.team .team-member:nth-child(n + 1) {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section.team .function {
  display: block;
}

section.team a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-decoration: none;
}

section.team .read-more-label {
  display: block;
  margin-top: 16px;
}

section.team .extended-biography {
  display: none;
}

@media (min-width: 576px) {
  section.team .team-member:nth-child(n + 2) {
    margin-top: 48px;
  }
}

@media (min-width: 768px) {
  section.team .team-member:nth-child(n + 4) {
    margin-top: 64px;
  }
}

section.logos .s-header-wrapper {
  text-align: center;
}

section.logos .s-header-wrapper .c-heading {
  margin-bottom: 60px;
}

section.logos .s-logos-wrapper .column {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

section.logos .s-logos-wrapper .column .c-image__img {
  text-align: center;
}

section.logos .s-button-wrapper {
  text-align: center;
  margin-top: 30px;
}

.site-header {
  z-index: 21;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  width: 100%;
  background-color: #d0ddf0;
}

.site-header__container {
  min-height: 80px;
  position: relative;
}

.site-header__branding,
.site-header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.site-header__branding {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0;
  background-image: none;
  height: 80px;
}

.site-header__branding-link:active,
.site-header__branding-link:focus,
.site-header__branding-link:hover {
  background-color: transparent;
  background-image: none;
}

.site-header__branding-logo .c-image__img {
  height: 35px;
  width: auto;
}

@media (min-width: 992px) {
  .site-header {
    overflow: visible;
  }

  .site-header__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 80px;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .site-header__branding {
    position: static;
    margin-right: 0;
    height: 80px;
  }

  .site-header__branding-logo .c-image__img {
    height: 50px;
  }
}

@media (min-width: 1280px) {
  .site-header__branding {
    margin-right: 0;
  }

  .site-header__branding-logo .c-image__img {
    height: 50px;
  }
}

.footer--simple {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #1075bc2b;
}

.footer--simple .col-12.bottom .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav {
  top: 0;
  left: 0;
  padding: 0;
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.nav,
.nav__toggler {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__toggler {
  min-height: 80px;
}

.nav__toggler-icon {
  width: 24px;
  height: 18px;
}

.nav__toggler-icon-inner {
  background-color: #0e76bc;
  width: 24px;
  border-radius: 0;
}

.nav__toggler-icon-inner:before {
  background-color: #0e76bc !important;
  width: 24px;
  top: 8px !important;
  border-radius: 0;
}

.nav__toggler-icon-inner:after {
  background-color: #0e76bc !important;
  width: 24px;
  top: 16px !important;
  border-radius: 0;
}

.nav__toggler-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  background-color: transparent;
  border: none;
  margin-left: auto;
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  color: #241f21;
  overflow: auto;
}

.nav__toggler-text {
  display: none;
}

.nav__container {
  display: none;
  width: 100%;
  position: relative;
  top: 10px;
  text-align: center;
}

.nav__menu {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 50px;
  text-align: center;
}

.nav__menu--primary {
  margin-top: 0;
}

.nav__menu--buttons {
  margin-top: 40px;
}

.nav__menu--buttons .nav-link,
.nav__menu--buttons .nav-link:focus,
.nav__menu--buttons .nav-link:hover {
  -webkit-transition: background-color 0.3s ease-in-out,
    border-bottom 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out,
    border-bottom 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
}

.nav__menu--buttons .nav-link:focus,
.nav__menu--buttons .nav-link:hover {
  border-bottom-color: transparent;
}

.nav__menu-item {
  list-style: none;
  margin: 10px 0;
}

.nav__menu-item--has-children {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.nav__menu-item--has-children > .nav-link:focus,
.nav__menu-item--has-children > .nav-link:hover {
  border-bottom-color: transparent;
}

.nav__menu-link {
  font-size: 14px;
  text-decoration: none;
  padding: 10px 0 2px;
  position: relative;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  border-bottom: 2px solid transparent;
  line-height: normal;
}

.nav__menu-link--current,
.nav__menu-link:active,
.nav__menu-link:focus,
.nav__menu-link:hover {
  text-decoration: none;
  color: #241f21;
  border-bottom: 2px solid #241f21;
}

.nav__menu-link--top-level {
  text-transform: none;
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  font-size: 17px;
}

.nav__sub-menu {
  padding-left: 0;
  width: 100%;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.nav__sub-menu-item {
  list-style: none;
  text-align: center;
}

.nav__sub-menu-link {
  text-decoration: none;
  margin-bottom: 0;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  background-image: none;
  color: #454b50c9;
  font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  font-size: 16px;
}

.nav__sub-menu-link--current,
.nav__sub-menu-link:active,
.nav__sub-menu-link:focus,
.nav__sub-menu-link:hover {
  background-color: transparent;
  text-decoration: none;
  color: #241f21;
  border-bottom: 2px solid #241f21;
}

.hamburger--spring.is-active .hamburger-inner:before {
  -webkit-transform: translateZ(0) rotate(-45deg) !important;
  transform: translateZ(0) rotate(-45deg) !important;
}

.hamburger--spring.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -8px, 0) rotate(45deg) !important;
  transform: translate3d(0, -8px, 0) rotate(45deg) !important;
}

.js-nav--expanded .nav__container {
  height: 100vh;
  padding-bottom: 40px;
}

@media (min-width: 1280px) {
  .nav {
    margin-bottom: 0;
    position: unset;
    margin-top: 2px;
    min-height: 80px;
  }

  .nav__container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    text-align: left;
    position: unset;
  }

  .nav__toggler {
    display: none;
  }

  .nav--primary {
    margin-right: unset;
  }

  .nav__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0;
    margin-left: 32px;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav__menu--primary {
    margin-left: 0;
  }

  .nav__menu--buttons .nav-link {
    min-width: 130px;
    text-align: center;
    display: block;
    background-color: #0e76bc;
    border-radius: 0.4rem;
    color: #fff;
    -ms-flex-item-align: center;
    align-self: center;
    font-family: GT-Walsheim-Bold, Verdana, sans-serif;
  }

  .nav__menu-item {
    margin: 0 32px 0 0;
    padding: 10px 0;
    color: #241f21;
    height: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav__menu-item:last-child {
    margin-right: 0;
  }

  .nav__menu-item--has-children {
    position: relative;
  }

  .nav__menu-item--has-children > .nav-link {
    margin-right: 8px;
  }

  .nav__menu-item--has-children:before {
    display: none;
  }

  .nav__menu-item--has-children:after {
    content: "";
    display: inline-block;
    margin-left: -4px;
    margin-bottom: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #241f21;
  }

  .nav__menu-item--has-children:hover .sub-menu {
    visibility: visible;
    opacity: 1;
  }

  .nav-link {
    padding: 0;
    color: #000;
    margin-bottom: 0;
  }

  .nav-link-current:before,
  .nav-link:active:before,
  .nav-link:focus:before,
  .nav-link:hover:before {
    top: 100%;
  }

  .nav__sub-menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 70px;
    left: -16px;
    background: #fff;
    -webkit-box-shadow: 0 0 35px -10px rgba(0, 0, 0, 0.27);
    box-shadow: 0 0 35px -10px rgba(0, 0, 0, 0.27);
    min-width: 200px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
  }

  .nav__sub-menu-link {
    color: #241f21;
    padding: 9px 16px;
    display: block;
    text-align: left;
  }

  .nav__sub-menu-link--current,
  .nav__sub-menu-link:active,
  .nav__sub-menu-link:focus,
  .nav__sub-menu-link:hover {
    background: #0e76bcc2;
    color: #fff;
    border-bottom: none;
  }

  .nav__sub-menu-link:before {
    display: none;
  }
}

@media (min-width: 1280px) {
  .nav__menu {
    margin-left: 25px;
  }

  .nav__menu-item {
    margin: 0 25px 0 0;
  }
}

section.single-post .images {
  margin-bottom: 32px;
}

section.single-post .images .image {
  text-align: center;
}

section.single-post .images img {
  width: 100%;
  height: auto;
  margin-bottom: 32px;
}

section.single-post .content {
  margin-bottom: 32px;
}

section.single-post .content figure {
  margin-top: 20px;
}

section.single-post .content figure img {
  width: 100%;
  height: auto;
}

section.single-post blockquote {
  margin-top: 50px;
  margin-bottom: 35px;
  padding: 4px 0;
  position: relative;
}

section.single-post blockquote p {
  font-size: 18px;
  line-height: 22px;
}

section.single-post blockquote:before {
  display: block;
  content: "\201C";
  font-size: 80px;
  position: absolute;
  left: 0;
  top: -65px;
}

section.single-post iframe {
  width: 100%;
  height: 220px;
}

@media (min-width: 576px) {
  section.single-post .images img {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  section.single-post ul li {
    font-size: 16px;
    line-height: 26px;
  }

  section.single-post blockquote {
    position: relative;
    margin: 32px 0;
    padding: 4px 50px;
  }

  section.single-post blockquote p {
    font-size: 24px;
    line-height: 1.45;
  }

  section.single-post blockquote:before {
    font-size: 80px;
    position: absolute;
    left: 0;
    top: -25px;
  }

  section.single-post iframe {
    width: 100%;
    height: 440px;
  }
}

@media (min-width: 992px) {
  section.single-post .content,
  section.single-post .images {
    margin-bottom: 64px;
  }
}

body#tinymce {
  margin: 12px !important;
}

.footer {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #d0ddf0;
}

.footer .c-social-accounts {
  margin-top: 24px;
  margin-bottom: 24px;
}

.footer__column-links {
  -webkit-column-count: 1;
  column-count: 1;
}

.footer__column-links-link {
  display: block;
  text-decoration: none;
}

.footer .card-title--small {
  margin-bottom: 16px;
  display: block;
}

.footer div[class*="column-"] {
  margin-bottom: 20px;
}

.footer div[class*="column-"]:last-of-type {
  margin-bottom: 0;
}

.footer a[href*="tel:"] {
  font-family: GT-Walsheim-Regular, Verdana, sans-serif;
  text-decoration: none;
}

.footer a[href*="mailto:"] {
  text-decoration: none;
}

.footer a[href*="mailto:"]:active,
.footer a[href*="mailto:"]:focus,
.footer a[href*="mailto:"]:hover {
  text-decoration: underline;
}

.footer .row.top {
  padding-bottom: 25px;
}

.footer .row.bottom .col-12 .wrapper {
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.footer__pipe {
  content: "|";
  color: #241f21;
  margin-left: 8px;
  margin-right: 8px;
  text-decoration: none;
}

.footer__follow p,
.footer__pipe {
  font-size: 16px;
}

.footer__follow a {
  margin-left: 4px;
  font-size: 63px;
}

.footer__follow a:first-of-type {
  margin-left: 10px;
}

.footer__copyright {
  font-size: 16px;
}

.footer__copyright p {
  margin-bottom: 0;
}

.footer__copyright .footer__pipe {
  display: none;
}

.footer__extra-links-link {
  text-decoration: none;
  font-size: 16px;
}

.footer__extra-links .footer__pipe:last-of-type {
  display: none;
}

.footer__logos img {
  height: 35px;
  width: auto;
}

.footer__webdesign-by {
  font-size: 16px;
}

.footer__webdesign-by a {
  text-decoration: none;
}

.footer__webdesign-by a:active,
.footer__webdesign-by a:focus,
.footer__webdesign-by a:hover {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .footer--two .footer__column-links {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 768px) {
  .footer {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .footer .row.bottom .col-12 .wrapper a,
  .footer .row.bottom .col-12 .wrapper div,
  .footer .row.bottom .col-12 .wrapper p {
    display: inline;
  }

  .footer__copyright .footer__pipe,
  .footer__extra-links .footer__pipe:last-of-type {
    display: inline-block;
  }

  .footer__pipe {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 992px) {
  .footer div[class*="column-"] {
    margin-bottom: 0;
  }

  .footer--one .footer__column-links-wrapper {
    -webkit-column-count: 4;
    column-count: 4;
  }
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login-form {
  width: 400px;
  border: 1px solid #ced4da;
  padding: 30px;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 10px #ced4da;
}

form label {
  font-weight: bold;
  font-size: 16px;
}

.login-form input {
  margin-bottom: 0px;
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  padding: 8px 15px;
}

.login-form input:focus {
  margin-bottom: 0px;
  display: block;
  width: 100%;
  border: 2px solid #0e76bc2e;
  border-radius: 0.4rem;
  padding: 8px 15px;
}

.login-form button {
  margin-top: 20px;
  width: 100%;
}

.btn-primary {
  color: #fff;
  padding: 5px 0;
  border-radius: 0.4rem;
  background-color: #0e76bc;
  border-color: #0e76bc;
}
.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color: #0e76bcc2;
  border-color: #0e76bcc2;
}

.login-container .logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forgotpw-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.forgotpw-form {
  width: 400px;
  border: 1px solid #ced4da;
  padding: 30px;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 10px #ced4da;
}

.forgotpw-form input {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  padding: 8px 15px;
}

.forgotpw-form input:focus {
  display: block;
  width: 100%;
  border: 2px solid #0e76bc2e;
  border-radius: 0.4rem;
  padding: 8px 15px;
}

.forgotpw-form button {
  margin-top: 20px;
  width: 100%;
}

.forgotpw-container .logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 180px;
  margin-bottom: 10px;
}

.logo-container p {
  width: 100%;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  background-color: #0e76bc2e;
  padding: 10px;
  margin: 25px 0 20px 0;
  border-radius: 0.4rem;
}

.reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.reset-form {
  width: 400px;
  border: 1px solid #ced4da;
  padding: 30px;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 10px #ced4da;
}

.reset-form input {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  padding: 8px 15px;
}

.reset-form input:focus {
  display: block;
  width: 100%;
  border: 2px solid #0e76bc2e;
  border-radius: 0.4rem;
  padding: 8px 15px;
}

.reset-form button {
  margin-top: 20px;
  width: 100%;
}

.reset-container .logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-container {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  color: #721c24;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
}

.error-message {
  margin: 0;
  font-size: 15px;
  text-align: center;
}

.antdButton {
  background-color: #0e76bc !important;
  color: #fff;
}
.antdButton:hover {
  background-color: #0e76bcc2 !important;
  color: #fff;
}

.download-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-p {
  width: 100%;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
  font-weight: bold;
  background-color: #0e76bc2e;
  padding: 10px;
  margin: 25px 0 20px 0;
  border-radius: 0.4rem;
}

.download-form {
  width: 400px;
  padding: 30px;
}

.download-form input {
  margin-bottom: 0px;
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  padding: 8px 15px;
}

.download-form input:focus {
  margin-bottom: 0px;
  display: block;
  width: 100%;
  border: 2px solid #0e76bc2e;
  border-radius: 0.4rem;
  padding: 8px 15px;
}

.table-fonts {
  font-family: "GT-Walsheim-Regular";
  font-size: "14px" !important;
  color: "#374151" !important;
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-white);
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#preloader #loading-center {
  width: 200px;
  height: 200px;
}

#preloader #object {
  width: 60px;
  height: 60px;
  background-color: var(--color-primary);
  -webkit-animation: preloader 1.2s infinite ease-in-out;
  animation: preloader 1.2s infinite ease-in-out;
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
}


:where(.css-dev-only-do-not-override-ph9edi).ant-float-btn-primary .ant-float-btn-body {
  background-color: #0e76bc;
  transition: background-color 0.2s;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-tooltip .ant-tooltip-inner {
  min-width: 32px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #0e76bc;
  border-radius: 6px;

}

:where(.css-dev-only-do-not-override-ph9edi).ant-tooltip {
  color: #0e76bc;
  --antd-arrow-background-color: #0e76bc;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.calendarContainer {
  display: flex;
  font-size: 14px;
  min-height: 100%;
}

.calendarSubContainer {
  flex-grow: 1;
  padding: 0 2em;
}

.fc .fc-button-primary {
  background-color: #0e76bc;
  color: white;
  border-color: #5db1eac2;
}
.fc .fc-button-primary:hover {
  background-color: #0e76bcc2;
  color: white;
  border-color: #0e76bcc2;
}
.fc .fc-button-primary:disabled {
  background-color: #d0ddf0;
  color: black;
  cursor: not-allowed;
  border-color: #e6e6e6;
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #1589d6e2;
  border-color: #0e76bc;
  font-weight: bold
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:focus {
  box-shadow: #1589d64b 0px 0px 0px 0.2rem;
}

.fc .fc-daygrid-day-frame {
  cursor: pointer;
}

.fc-h-event {
  display: block;
  border: 1px solid #047781;
  background-color: #7ed2b8;
}
